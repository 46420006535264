import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import _ from 'lodash'

import Icon from 'components/_scaffolding/Icon'
import Tabs from 'components/_scaffolding/Tabs'

import classes from './OverlayHeader.module.scss'

const OverlayHeader = ({
	className = null,
	handleBackClick = null,
	handleCloseClick = null,
	handleTabClick = _.noop,
	headerAction = null,
	idActiveTab = null,
	secondLineContent = null,
	tabs = [],
	title,
}) => {
	return (
		<div>
			<div
				className={classnames(classes.header, {
					[className]: className !== null,
				})}
			>
				{handleBackClick !== null && (
					<div className={classes.header__icon} onClick={handleBackClick}>
						<Icon name={Icon.NAMES.ARROW_LEFT} size={20} customClassName={classes.icon} />
					</div>
				)}
				<div
					className={classnames(classes['header__content-wrapper'], {
						[classes['header--without-close-button']]: handleCloseClick === null,
					})}
				>
					<div className={classes.content}>
						<div className={classes.content__title}>{title}</div>
						{tabs.length !== 0 && (
							<Tabs handleTabClick={handleTabClick} idActiveTab={idActiveTab} tabs={tabs} />
						)}
					</div>
					{headerAction !== null && headerAction}
				</div>
				{handleCloseClick !== null && (
					<div className={classes.header__icon} onClick={handleCloseClick}>
						<Icon name={Icon.NAMES.X_CLOSE} size={20} customClassName={classes['icon']} />
					</div>
				)}
			</div>
			{secondLineContent !== null && secondLineContent}
		</div>
	)
}

OverlayHeader.propTypes = {
	className: PropTypes.string,
	handleBackClick: PropTypes.func,
	handleCloseClick: PropTypes.func,
	handleTabClick: PropTypes.func,
	headerAction: PropTypes.node,
	idActiveTab: PropTypes.string,
	secondLineContent: PropTypes.node,
	tabs: PropTypes.arrayOf(
		PropTypes.exact({ label: PropTypes.string.isRequired, idTab: PropTypes.string.isRequired }),
	),
	title: PropTypes.node.isRequired,
}

export default OverlayHeader
