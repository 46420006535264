import PropTypes from 'prop-types'
import classnames from 'classnames'
import React, { Fragment } from 'react'
import _ from 'lodash'

import SelectMenuOptionComponent from 'components/_scaffolding/_input/SelectMenuOptionComponent'

import {
	SELECT_MENU_TYPE,
	customSelectOptionShape,
	customSelectSectionShape,
} from 'constants/select'

import classes from './SelectMenuComponent.module.scss'

const SelectMenuComponent = ({
	className,
	close,
	hasWrapper,
	hasSections,
	isDisabled,
	menuType,
	options,
}) => {
	const menuClassName = classnames({
		[classes.menu]: hasWrapper === true,
		[className]: className !== null,
	})

	const getHandleOptionClick = option => event => {
		event.stopPropagation()

		if (isDisabled === true) {
			return
		}

		if (option.isInfo === true) {
			// info options behave like additional information label, not active options
			return
		}

		if (_.isNil(option.linkTo) === true) {
			option.value()
		}

		if (option.shouldCloseSelect !== false) {
			close()
		}
	}

	const renderOptions = optionsToRender => (
		<Fragment>
			{optionsToRender.map((option, optionIndex) => (
				<SelectMenuOptionComponent
					handleOptionClick={getHandleOptionClick(option)}
					key={optionIndex}
					menuType={menuType}
					option={option}
				/>
			))}
		</Fragment>
	)

	if (hasSections === false) {
		return <div className={menuClassName}>{renderOptions(options)}</div>
	}

	return (
		<div className={menuClassName}>
			{options.map((section, sectionIndex) => (
				<div
					key={sectionIndex}
					className={classnames({
						[section.className]: _.isNil(section.className) === false,
					})}
				>
					<div className={classes.section}>
						{section.label !== null && (
							<div
								className={classnames(classes['section__label'], {
									[classes['section__label--margin']]: menuType === SELECT_MENU_TYPE.WIDE,
								})}
							>
								{section.label}
							</div>
						)}
						{(section.label !== null || sectionIndex !== 0) && (
							<div className={classes.section__border} />
						)}
					</div>
					{renderOptions(section.options)}
				</div>
			))}
		</div>
	)
}

SelectMenuComponent.MENU_TYPE = SELECT_MENU_TYPE

SelectMenuComponent.defaultProps = {
	className: null,
	close: () => {},
	hasSections: false,
	hasWrapper: true,
	isDisabled: false,
	menuType: SELECT_MENU_TYPE.DEFAULT,
}

SelectMenuComponent.propTypes = {
	className: PropTypes.string,
	close: PropTypes.func.isRequired,
	hasSections: PropTypes.bool.isRequired,
	hasWrapper: PropTypes.bool.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	menuType: PropTypes.oneOf(Object.keys(SELECT_MENU_TYPE)).isRequired,
	options: PropTypes.oneOfType([
		PropTypes.arrayOf(customSelectOptionShape),
		PropTypes.arrayOf(customSelectSectionShape),
	]).isRequired,
}

export default SelectMenuComponent
