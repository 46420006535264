import { gql } from '@apollo/client'

export const groupSolverPanelInfo = gql`
	fragment groupSolverPanelInfo on PanelProvider {
		groupSolverPanelInfo {
			expectedCompletes
			expectedCompletionLoi
			expectedIncidenceRate
			expectedDays
			industry
			collectsPii
		}
	}
`

export const groupSolverPanelPayment = gql`
	fragment groupSolverPanelPayment on PanelProvider {
		payment {
			idPanelProviderPayment
			clientSecret
			error
			paymentStatus
			priceEstimate
			shouldCreatePayment
		}
	}
`

export const respondentSource = gql`
	fragment respondentSource on RespondentSource {
		isEnabled
		forcedRedirects {
			complete
			overquota
			terminate
			qualityTerminate
		}
		idRespondentSource
		label
		loginMethod
		isStopped
		loginScreen {
			publicLabel
			studyDescription
			logo
			visibleButtons {
				anonymous
				email
			}
		}
		matchParams
		sourceType
		provider {
			idPanelProvider
			isFinished
			providerType
			requestedCompletes
			info {
				surveyNumber
				surveyQuotaID
				increment
			}
			community {
				communityConditions
				idCommunity
				invitationSettings {
					batchSize
				}
				invitationEmailSettings {
					emailButtonLabel
					emailHeadline
					emailSubject
					emailTemplate
					idEmailModule
					idNameModule
				}
			}
			syntheticRespondents {
				idPromptModule
				idOrganicRespondentModule
			}
			...groupSolverPanelInfo
			...groupSolverPanelPayment
			demographics {
				idDemographic
				name
				isActive
				quotas {
					idDemographicQuota
					id
					name
					completesRatio
				}
			}
		}
	}

	${groupSolverPanelInfo}
	${groupSolverPanelPayment}
`
