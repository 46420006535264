import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classnames from 'classnames'

import AdvancedFeatureLabel from 'components/_scaffolding/AdvancedFeatureLabel'
import Badge from 'components/_scaffolding/Badge'
import Link from 'components/_scaffolding/_links/Link'
import Tooltip from 'components/_scaffolding/Tooltip'

import SelectDropdownWrapper from 'components/_scaffolding/_select/SelectDropdownWrapper'
import SelectMenuComponent from 'components/_scaffolding/_input/SelectMenuComponent'

import { SELECT_MENU_POSITION, SELECT_MENU_TYPE } from 'constants/select'

import classes from './Tab.module.scss'

const Tab = ({
	handleTabClick,
	hasError = false,
	idProductTour = null,
	idTab,
	isActive,
	isBeta = false,
	isDisabled = false,
	isInternal = false,
	isSelect = false,
	label,
	linkTo = null,
	secondaryLabel = null,
	selectOptions = [],
	tooltipText = null,
}) => {
	if (isSelect === true && selectOptions.length === 0) {
		throw new Error('select tab has to have options')
	}

	if (isSelect === true && linkTo !== null) {
		throw new Error('select cannot be a link')
	}

	const handleClick = () => {
		if (isDisabled === true || isSelect === true) {
			return
		}

		handleTabClick(idTab)
	}

	const WrapperComponent = linkTo === null ? Fragment : Link

	const wrapperProps =
		linkTo === null ? {} : { to: linkTo, className: classes.wrapper, isDisabled, tooltipText }

	const renderTabLabel = () => (
		<div
			className={classnames(classes.tab, {
				[classes['tab--active']]: isActive === true,
				[classes['tab--disabled']]: isDisabled === true,
			})}
			data-testid={idTab}
			onClick={handleClick}
			data-for={idTab}
			data-tip={tooltipText}
			data-tip-disable={isDisabled !== true}
			data-place="bottom"
			data-intercom-target={idProductTour}
		>
			{label}
			{secondaryLabel ?? null}
			{hasError === true && <div className={classes.tab__error} />}
			{isBeta === true && <Badge preset={Badge.PRESETS.beta} />}
			{isInternal === true && <AdvancedFeatureLabel />}
		</div>
	)

	const renderContent = () => {
		if (isSelect === false) {
			return renderTabLabel()
		}

		return (
			<SelectDropdownWrapper
				ControlComponent={renderTabLabel}
				MenuComponent={SelectMenuComponent}
				className={classes.select}
				controlClassName={classes.select__control}
				controlProps={{}}
				isDisabled={isDisabled}
				menuClassName={classes.select__menu}
				menuProps={{
					options: selectOptions,
					className: classes.select__menu,
					menuType: SELECT_MENU_TYPE.WIDE,
				}}
				position={SELECT_MENU_POSITION.BOTTOM_RIGHT}
				wrapperClassName={classes.select}
			/>
		)
	}

	return (
		<WrapperComponent {...wrapperProps}>
			{renderContent()}
			<Tooltip id={idTab} />
		</WrapperComponent>
	)
}

Tab.propTypes = {
	handleTabClick: PropTypes.func.isRequired,
	hasError: PropTypes.bool,
	idProductTour: PropTypes.string,
	idTab: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	isBeta: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isInternal: PropTypes.bool,
	isSelect: PropTypes.bool,
	label: PropTypes.string.isRequired,
	linkTo: PropTypes.string,
	secondaryLabel: PropTypes.element,
	selectOptions: PropTypes.arrayOf(PropTypes.object),
	tooltipText: PropTypes.string,
}

export default Tab
