import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

import { SELECT_MENU_TYPE, customSelectOptionShape } from 'constants/select'

import Icon from 'components/_scaffolding/Icon'
import Link from 'components/_scaffolding/_links/Link'
import Tooltip from 'components/_scaffolding/Tooltip'

import classes from './SelectMenuOptionComponent.module.scss'

const SelectMenuOptionComponent = ({ handleOptionClick, menuType, option }) => {
	const navigate = useNavigate()

	const {
		isNegative,
		isDisabled,
		isGoBack,
		isHighlighted,
		isInfo,
		isSecondary,
		label,
		linkTo,
		isFullWidth,
	} = option

	const iconName = option.iconName ?? null
	const secondaryLabel = option.secondaryLabel ?? null
	const iconClassName = option.iconClassName ?? null
	const tooltipText = option.tooltipText ?? null
	const tooltipTextOnDisabled = option.tooltipTextOnDisabled ?? null
	const tooltipProps = option.tooltipProps ?? {}
	const optionTooltip =
		isDisabled === true && tooltipTextOnDisabled !== null ? tooltipTextOnDisabled : tooltipText

	const handleClick = event => {
		if (isDisabled === true) {
			return
		}

		if (_.isNil(linkTo) === false && linkTo.startsWith('/')) {
			event.stopPropagation()
			event.preventDefault()
			navigate(linkTo)
		} else {
			handleOptionClick(event)
		}
	}

	if (isGoBack === true) {
		return (
			<div
				className={classnames(classes['option-holder'], classes['option-holder--go-back'])}
				onClick={handleClick}
				title={label}
			>
				<Icon name={Icon.NAMES.ARROW_LEFT} size={20} customClassName={classes['icon--back']} />
				{label}
			</div>
		)
	}

	const WrapperComponent = _.isNil(linkTo) === true ? Fragment : Link

	const wrapperProps = _.isNil(linkTo) === true ? {} : { to: linkTo }

	return (
		<WrapperComponent {...wrapperProps}>
			<div
				className={classnames(classes['option-holder'], {
					[classes['option-holder--alert']]: isNegative === true,
					[classes['option-holder--highlighted']]: isHighlighted === true,
					[classes['option-holder--info']]: isInfo === true,
					[classes['option-holder--secondary']]: isSecondary === true,
					[classes['option-holder--disabled']]: isDisabled === true,
					[option.className]: _.isNil(option.className) === false,
				})}
				onClick={handleClick}
				data-for={optionTooltip}
				data-tip={optionTooltip}
				title={label}
			>
				<div
					className={classnames(classes['option-holder__label'], {
						[classes['option-holder__label--bigger-width']]: menuType === SELECT_MENU_TYPE.WIDE,
						[classes['option-holder__label--info']]: isInfo === true,
						[classes['option-holder__label--with-icon']]: iconName !== null,
						[classes['option-holder__label--full-width']]: isFullWidth === true,
						[option.labelClassName]: _.isNil(option.labelClassName) === false,
					})}
				>
					{iconName !== null && (
						<Icon
							name={iconName}
							size={20}
							customClassName={classnames({ [iconClassName]: iconClassName !== null })}
						/>
					)}
					{label}
				</div>
				{secondaryLabel !== null && secondaryLabel}
			</div>
			{optionTooltip !== null && <Tooltip {...tooltipProps} id={optionTooltip} />}
		</WrapperComponent>
	)
}

SelectMenuOptionComponent.defaultProps = {
	menuType: SELECT_MENU_TYPE.DEFAULT,
}

SelectMenuOptionComponent.propTypes = {
	handleOptionClick: PropTypes.func.isRequired,
	menuType: PropTypes.oneOf(Object.keys(SELECT_MENU_TYPE)).isRequired,
	option: customSelectOptionShape.isRequired,
}

export default SelectMenuOptionComponent
